// ContactUsRouting.js
import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from 'react-router-dom';
const NavigationBar = lazy(()=> import('../../Navbar/NavigationBar'));
const ContactHero = lazy(()=> import('./ContactHero/ContactHero'));
const ContactForm = lazy(()=> import('./ContactForm/ContactForm'));
const ContactDetails = lazy(()=> import('./ContactDetails/ContactDetails'));
const Footer = lazy(()=> import('../Footer/Footer'));

// const LandingBlogs = lazy(() => import("./LandingBlogs/LandingBlogs"));

const ContactUsRouting = ({ onNavClick,targetSection,lang }) => {
  const navigate = useNavigate();

  const contactRef = useRef(null);

  const sectionRefs = {
    contact: contactRef,
  };

  useEffect(() => {
    if (targetSection && sectionRefs[targetSection]) {
      sectionRefs[targetSection].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [targetSection]);

  const handleNavClick = (section) => {
    if (section === 'contact') {
      navigate('/en/contact');
    } else {
      onNavClick(section); 
      navigate('/'); // Navigate back to the landing page
    }
  };

 

  return (
    <div>
      <section ref={contactRef} className="section">
        <Suspense fallback={<div></div>}>
        <div className='navbarPositions'>
            <NavigationBar onNavClick={handleNavClick} lang={lang} />
          </div>
      <ContactHero />
      {/* <ContactForm /> */}
      <ContactDetails />
      <Footer />
        </Suspense>
      </section>
      
    </div>
  );
};

export default ContactUsRouting;