import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
const NavigationBar = lazy(()=>import('../../Navbar/NavigationBar'))
const ArabicLandingHero = lazy(()=>import('./ArabicLandingHero/ArabicLandingHero'))
const ArabicLandingMarketInfo = lazy(()=>import('./ArabicLandingMarketInfo/ArabicLandingMarketInfo'))
const ArabicLandingPlatformMt5 = lazy(()=>import('./ArabicPlatform/ArabicLandingPlatformMt5'))
const ArabicLandingWhyInvest = lazy(()=>import('./ArabicLandingWhyInvest/ArabicLandingWhyInvest'))
const ArabicLandingKamaTeam = lazy(()=>import('./ArabicLandingKamaTeam/ArabicLandingKamaTeam'))
const ArabicFooter = lazy(()=>import('../ArabicFooter/ArabicFooter'))

const ArabicLandingRouting = React.memo(({ onNavClick, targetSection,lang }) => {
    const navigate = useNavigate()
    const homeRef = useRef(null);
    const marketRef = useRef(null);
    const platformRef = useRef(null);
    const aboutRef = useRef(null);

    const sectionRefs = {
        home: homeRef,
        market: marketRef,
        platform: platformRef,
        about: aboutRef,
      };

      useEffect(() => {
        if (targetSection && sectionRefs[targetSection]) {
          sectionRefs[targetSection].current.scrollIntoView({ behavior: "smooth" });
        }
      }, [targetSection]);
    
      const handleNavClick = (section, isExternal) => {
        console.log("Navigating to section:", section);
        onNavClick(section);
        if (isExternal) {
          navigate('/en/contact');
        } else {
          navigate('/');
        }
      };

      

      
  return (
    <div className="landing-page-container" >
    <div className="navbarPositions">
      <Suspense fallback={<div></div>}>
        <NavigationBar onNavClick={handleNavClick} lang={lang}/>
      </Suspense>
    </div>
    <section ref={homeRef} className="section">
      <Suspense fallback={<div></div>}>
      <ArabicLandingHero  />
      </Suspense>
    </section>
    <section ref={marketRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingMarketInfo />
        </Suspense>
      </section>
      <section ref={platformRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingPlatformMt5 />
        </Suspense>
      </section>
      <section ref={aboutRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingWhyInvest/>
          <ArabicLandingKamaTeam/>
        </Suspense>
      </section>
      <Suspense fallback={<div></div>}>
        <ArabicFooter />
      </Suspense>
  </div>
  )
})

export default React.memo(ArabicLandingRouting);
