// LandingPageRouting.js
import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
const LandingHero = lazy(() =>
  import("./LandingHero/LandingHero")
);
const LandingMarketInfo = lazy(() =>
  import("./LandingMarketInfo/LandingMarketInfo")
);
const LandingPlatformMt5 = lazy(() => import("./Platform/LandingPlatformMt5"));
const LandingWhyInvest = lazy(() =>
  import("./LandingWhyInvest/LandingWhyInvest")
);
const LandingKamaTeam = lazy(() =>
  import("./LandingKamaTeam/LandingKamaTeam")
);
const LandingBlogs = lazy(() => import("./LandingBlogs/LandingBlogs"));
const Footer = lazy(() => import("../Footer/Footer"));
const NavigationBar = lazy(() => import("../../Navbar/NavigationBar"));

const LandingPageRouting = ({ onNavClick, targetSection,lang }) => {
  const navigate = useNavigate();
  const homeRef = useRef(null);
  const marketRef = useRef(null);
  const platformRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const sectionRefs = {
    home: homeRef,
    market: marketRef,
    platform: platformRef,
    about: aboutRef,
    contact: contactRef,
  };

  useEffect(() => {
    if (targetSection && sectionRefs[targetSection]) {
      sectionRefs[targetSection].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [targetSection]);

  const handleNavClick = (section, isExternal) => {
    console.log("Navigating to section:", section);
    onNavClick(section);
    if (isExternal) {
      navigate('/en/contact');
    } else {
      navigate('/');
    }
  };
  
  const scrollToMarket = () => {
    if (marketRef.current) {
      marketRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="landing-page-container" >
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar onNavClick={handleNavClick} lang={lang}/>
        </Suspense>
      </div>
      <section ref={homeRef} className="section">
        <Suspense fallback={<div></div>}>
        <LandingHero onExploreClick={scrollToMarket} />
        </Suspense>
      </section>
      <section ref={marketRef} className="section">
        <Suspense fallback={<div></div>}>
          <LandingMarketInfo />
        </Suspense>
      </section>
      <section ref={platformRef} className="section">
        <Suspense fallback={<div></div>}>
          <LandingPlatformMt5 />
        </Suspense>
      </section>
      <section ref={aboutRef} className="section">
        <Suspense fallback={<div></div>}>
          <LandingWhyInvest />
          <LandingKamaTeam />
          {/* <LandingBlogs /> */}
        </Suspense>
      </section>
      <Suspense fallback={<div></div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default LandingPageRouting;
