import React from 'react';
import './FloatingPhoneCall.css';
import { FiPhoneCall } from 'react-icons/fi';

const FloatingPhoneCall = () => {
  return (
    <div className="floating-phone-call" onClick={() => window.location.href = 'tel:+18667326535'}>
      <FiPhoneCall size={24} color="#fff" />
    </div>
  );
};

export default FloatingPhoneCall;
