// ContactUsRouting.js
import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from 'react-router-dom';
const NavigationBar = lazy(()=> import('../../Navbar/NavigationBar'));
const ArabicContactHero = lazy(()=> import('./ArabicContactHero/ArabicContactHero'));
const ArabicContactDetails = lazy(()=> import('./ArabicContactDetails/ArabicContactDetails'));
const ArabicFooter = lazy(()=> import('../ArabicFooter/ArabicFooter'));

const ArabicContactUsRouting = ({ onNavClick,targetSection,lang }) => {
  const navigate = useNavigate();

  const contactRef = useRef(null);

  const sectionRefs = {
    contact: contactRef,
  };

  useEffect(() => {
    if (targetSection && sectionRefs[targetSection]) {
      sectionRefs[targetSection].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [targetSection]);

  const handleNavClick = (section) => {
    if (section === 'contact') {
      navigate('/ar/contact');
    } else {
      onNavClick(section); 
      navigate('/'); // Navigate back to the landing page
    }
  };

 

  return (
    <div>
      <section ref={contactRef} className="section">
        <Suspense fallback={<div></div>}>
        <div className='navbarPositions'>
            <NavigationBar onNavClick={handleNavClick} lang={lang} />
          </div>
      <ArabicContactHero />
      <ArabicContactDetails/>
      <ArabicFooter />
        </Suspense>
      </section>
      
    </div>
  );
};

export default ArabicContactUsRouting;